const selector = '[data-recaptcha]:not([data-recaptcha-loaded])';
const instances = {};
let initialised = false;
let loaded = false;
let submit = false;

const setup = function() {

	$(selector).each(function() {

		var target = $(this),
			id = target.attr('id'),
			form = target.closest('form');

		target.attr('data-recaptcha-loaded', true);
		instances[id] = window.grecaptcha.render(id, {
			'sitekey': window.recaptchaPublicKey,
			'size': 'invisible',
			'badge': 'inline',
			'callback': function(token) {
				submit = true;
				target.closest('form').find('.g-recaptcha-response').val(token);
				target.closest('form').submit();
			}
		});

		form.on('submit', function(e) {

			// reset validation
			form.removeData('validator');
			form.removeData('unobtrusiveValidation');

			// validate form
			$.validator.unobtrusive.parse(form);

			if (form.valid() && !submit) {

				window.grecaptcha.execute(instances[id]);
				e.preventDefault();
				return false;
			}
		});
	});

	loaded = true;
}

const init = function() {

	if ($(selector).length) {

		if (!initialised) {

			initialised = true;
			window.initRecaptcha = setup;
			var script1 = document.createElement('script');
			script1.innerHTML = 'var recaptchaLoaded = function() { window.initRecaptcha(); }';
			document.body.appendChild(script1);

			var script2 = document.createElement('script');
			script2.async = true;
			script2.src = 'https://www.google.com/recaptcha/api.js?render=explicit&onload=recaptchaLoaded';
			document.body.appendChild(script2);

		} else {

			if (loaded) { setup(); }

		}
	}
}


export default init;
export { submit }
